<!--
 * @Author: your name
 * @Date: 2020-11-24 12:17:18
 * @LastEditTime: 2021-05-10 09:54:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\adminInverter\adminInverter.vue
-->
<template>
    <div class="adminInverter-main-box">
        <div class="search-box">
            <div class="search-box-sub">
                <span class="lable-text">{{$t('project.data.inv-type')}}</span>
                <el-input v-model="inverterType" style="width:160px;" :placeholder="$t('common.info.pl-insert-content')"></el-input>
            </div>
            <div class="search-box-sub">
                <el-button type="primary" @click='query'>{{$t('common.button.query')}}</el-button>
            </div>
        </div>
        <div class="button-box">
            <el-button type="primary" icon='el-icon-plus' class="marginR10" @click="()=>{$router.push({path:'/createInverter'})}">{{$t('project.new.inv')}}</el-button>
            <el-popconfirm
                    :title="$t('common.button.del-bath-confirm')"
            @confirm='systemTinverterDeleteBatch()'
            >
                <el-button slot="reference" icon='el-icon-delete' class="marginR10" >{{$t('link.delete')}}</el-button>
            </el-popconfirm>
            <el-button type="primary" icon='el-icon-edit' class="pull-right" @click="dialogVisible = true ">{{$t('system.import')}}</el-button>
        </div>
        <baseTable v-loading="loading"  row-key='sid' :data="dataRows" border  stripe   :column='column' :select='true' @selection-change="handleSelectionChange($event)" >
            <template #pInvDCmax='{data}'>
                {{data.pInvDCmax.toFixed(2)}}
            </template>
            <template #pInvACmax='{data}'>
                {{data.pInvACmax.toFixed(2)}}
            </template>
            <template #option='{data}'>
                <el-popconfirm
                title="确定删除该数据吗？"
                @confirm='systemTinverterDelete(data.invID)'
                >
                    <i class="el-icon-delete" slot="reference"></i>
                </el-popconfirm>
            </template>
            <template #name='{data}'>
                <a href="javascript:;" style="color: #4c98d9;" @click='pushEditUrl(data.name,data.invID)'>{{data.name}}</a>
            </template>
        </baseTable>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="[10, 20, 30, 50]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
        </el-pagination>

        <!--导入弹出框-->
        <el-dialog
                :title="$t('project.inv.import')"
                :visible.sync="dialogVisible"
                width="30%"
                v-if='dialogVisible'
        >
            <el-button type="text" @click="download">
                {{$t('system.template.upload')}}
            </el-button>
            <base-file v-model="uploadFile" ></base-file>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{$t('system.button.cancel')}}</el-button>
                <el-button type="primary" :loading="loadingBtn" @click="submitTinverter">{{$t('common.button.ok')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import baseTable from '../../components/common/baseTable'
import baseFile from '../../components/common/baseFileInputNew'
export default {
    components: {
        baseTable,
        baseFile
    },
    props: {

    },
    data() {
        return {
            dialogVisible: false,
            uploadFile: '',
            inverterType:'',
            selectDataList:[],
            loading:false,
            loadingBtn:false,
            dataRows:[],
            column:[{
                title: this.$t('project.data.inv-type'),
                value: 'name',
                slot: 'name'
            },
            {
                title: this.$t('new.PV.management.Max.DC-power') + 'kW',
                value: 'pInvDCmax',
                slot: 'pInvDCmax'
            },{
                title: this.$t('project.Max.AC-power') + ' V',
                value: 'pInvACmax',
                slot: 'pInvACmax'
            },
            {
                title: this.$t('project.max-efficiency'),
                value: 'etaInvMax',
                slot: 'etaInvMax'
            },
            {
                title: this.$t('project.european-efficiency'),
                value: 'etaInvEuropean',
                slot: 'etaInvEuropean'
            },{
                title: this.$t('import.time'),
                value: 'importTime',
                slot: 'importTime'
            },{
                title: this.$t('common.msg.opeation'),
                value: 'option',
                slot: 'option'
            }],
            size:10,
            current:1,
            total:1
        };
    },
    computed: {

    },
    watch: {

    },
    created() {

    },
    mounted() {
        this.systemTinverterGetPage()
    },
    methods: {

        /*提交导入*/
        async submitTinverter(){
            if (!this.uploadFile) {
                this.$message({
                    message: this.$t('remind.upload-file-first'),
                    type: 'warning'
                });
                return
            }
            let uploadFile = this.uploadFile
            // if(uploadFile.name.indexOf("inverter") < 0 || uploadFile.name.indexOf(".xls") < 0){
            //     this.$message({
            //         message: this.$t('project.file.format'),
            //         type: 'warning'
            //     });
            //     return
            // }
            this.loadingBtn = true
            let res = await this.API.systemTinverterImport({
                uploadFile: uploadFile
            })
            this.loadingBtn = false
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.dialogVisible = false
                this.uploadFile = ''
                this.systemTinverterGetPage()
            }else {
                this.$message({
                    message: res.msg,
                    type: 'warning'
                });
            }
        },

        /*下载模板文件*/
        download(){
            window.open(window.location.href.split('#')[0] + 'static/inverter.xls')
        },


        async systemTinverterDeleteBatch(){
            let res = await this.API.systemTinverterDeleteBatch({
                invIDs:this.selectDataList
            })
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.systemTinverterGetPage()
            }else{
                this.$message({
                    message: this.$t('operate.result.fail'),
                    type: 'warning'
                });
            }
        },
        async systemTinverterDelete(id){
            let res = await this.API.systemTinverterDelete({
                invID:id
            })
            if(res.success){
                this.$message({
                    message: this.$t('operate.result.success'),
                    type: 'success'
                });
                this.systemTinverterGetPage()
            }else{
                this.$message({
                    message: this.$t('operate.result.fail'),
                    type: 'warning'
                });
            }
        },
        pushEditUrl(name,id){
            this.$router.push({path: '/editInverter', query: {name: name,id:id}});
        },
        async systemTinverterGetPage(){
            this.loading = true
            let res = await this.API.systemTinverterGetPage({
                name:this.inverterType,
                current:this.current,
                size:this.size,
            })
            this.dataRows = res.rows
            this.total= parseInt(res.total)
            this.loading = false
            
        },
        query(){
            this.current = 1
            this.systemTinverterGetPage()
        },
        handleSelectionChange(val){
          this.selectDataList=val.map( item=>{
            return item.invID
          })
        },
        handleSizeChange(val) {
          this.size = val
          this.current = 1
          this.systemTinverterGetPage()
        },
        handleCurrentChange(val) {
          this.current = val
          this.systemTinverterGetPage()
        },
    },
};
</script>

<style  lang="less" scoped>
.adminInverter-main-box{
    
}
</style>
